import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./ChatModal.css";
import { useParams } from 'react-router-dom';
import { singelOrderChatDetails } from '../../../redux/slice/SingleOrderChat';
import { sendOrderMessage } from '../../../services/Order.service';
import { ADMIN } from '../../../services/admin.service';
import EmojiPicker from 'emoji-picker-react'
import { menuCounts } from '../../../redux/slice/menuCountSlice';
import { useTranslation } from 'react-i18next';
import BouncingLoader from '../../../constants/BouncingLoader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, } from "firebase/storage";
import { fireStore } from '../../../firebase/config';
import Box from '@mui/material/Box';
import Modal from "react-modal";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Close from "../../../assets/Images/svgs/CloseIcon.svg";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { getTimestampString } from '../../../constants/timestamp';

const style = {
  p: 4,
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "500px",
    overflow: "hidden"
  },
};

const ChatModal = ({ orderData, laung }) => {

  const dispatch = useDispatch()
  const params = useParams();
  const { ovid } = params;
  const messagesEndRef = useRef(null)
  const textareaRef = useRef(null);
  const { t } = useTranslation()
  const chatMessagesContainerRef = useRef(null);
  const inputFileRef = useRef();

  const [showEmojis, seetShowEmojis] = useState(false)
  const [textInput, setTextInput] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [chatPic, setChatPic] = useState("");
  const [imgPreview, setImgPreview] = useState(false);
  const [displayImage, setDisplayImage] = useState("");
  const [fileName, setFileName] = useState("");

  const chatDetials = useSelector((state) => state?.orderChatDetails?.data?.response);

  useEffect(() => {
    const interval = setInterval(() => {
      (orderData.status !== "105" || orderData.status !== "106" || orderData?.status === "107") &&
        dispatch(singelOrderChatDetails(ovid))
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setIsLoading(false)
    dispatch(menuCounts());
    dispatch(singelOrderChatDetails(ovid))
  }, [])



  const sendMessage = () => {

    setCount(() => count + 1)
    const data = {
      order_vendor_id: ovid,
      // text: inputText,
      image: chatPic,
      image_name: fileName
    };
    sendOrderMessage(data)
      .then(res => {
        if (res.data.status) {
          if (inputFileRef.current) {
            inputFileRef.current.value = null;
          }
          setChatPic("")
          setFileName("")
          dispatch(singelOrderChatDetails(ovid))
          seetShowEmojis(false)
        } else { ADMIN.toast.error(res.data.response.message) }
      })
      .catch(e => ADMIN.toast.error(e.message))
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleTextareaChange = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    const textWithLineBreaks = textarea.value.replace(/\n/g, '\n');
    textarea.value = textWithLineBreaks;
    setTextInput(textarea.value)
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatDetials]);

  const handleSelectEmoji = (e) => {
    setTextInput((prev) => prev + e.emoji)
    textareaRef.current.value += e.emoji;
  };

  const handleFileInputChange = () => {
    const selectedFile = inputFileRef.current.files[0];
    if (selectedFile) {
      const storageref = selectedFile && ref(getStorage(fireStore), `/chats/${orderData.order_vendor_id}/${selectedFile.name}/${getTimestampString()}`)
      const uploadTask = uploadBytesResumable(storageref, selectedFile);
      uploadTask.on(
        "state_changed", (snapshot) => {
        },
        (err) => {
          ADMIN.toast.error(err)
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            if (url.includes(".xlsx") || url.includes(".pdf")) {
              setFileName(selectedFile.name)
            }
            setChatPic(url)
          }).catch((err) => {
            ADMIN.toast.error(err)
          })
        }
      );
    } else { return }
  };

  const handlePreviewImage = (picture) => {
    setImgPreview(true)
  };

  const closeModal = () => {
    setImgPreview(false)
  };

  const handleClerImageData = () => {
    setChatPic("");
    setFileName("")
  }

  return (
    <div className="chat-box-container">
      <h1 className="chat_box_heading" style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Payment Details")}</h1>
      <div className="chat-box" ref={chatMessagesContainerRef}>
        {isLoading ? <BouncingLoader /> :
          <div className="chat-messages" >
            {chatDetials && chatDetials?.chats.map((message, index) => (
              <div
                key={index}

                className={`message ${message.text_from === '3' ? 'left' : 'right'}`}
              >
                <div className={`message-content ${message.text_from === '3' ? 'blue' : 'red'}`}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {message.image.length != 0 &&
                      <>
                        {message.image.includes(".png") || message.image.includes(".jpg") || message.image.includes(".jpeg") || message.image.includes(".gif") || message.image.includes(".svg") ?
                          <img src={message.image} alt="" onClick={() => { handlePreviewImage(message.image); setDisplayImage(message.image) }} style={{ height: "10rem", width: "10rem", padding: "5px" }} />
                          :
                          <a href={message.image} target="_blank" rel="noopener noreferrer" style={{ display: "flex", alignItems: "center", columnGap: "15px" }}>
                            <ArrowCircleDownIcon fontSize='large' />
                            {message.image_name ?
                              <p style={{ fontSize: "16px", fontFamily: "Cairo-Regular", color: "#2a2821" }} >{message.image_name}</p> :
                              <p style={{ fontSize: "16px", fontFamily: "Cairo-Regular", color: "#2a2821" }} >{t("download file")}</p>}
                          </a>}
                      </>
                    }
                    <span
                      className="content"
                      style={{ fontFamily: laung == "ar" && "Cairo-Regular", whiteSpace: "pre-line" }}
                    >{message.text}</span>
                    <br />
                  </div>
                  <span className="message-time" style={{ fontFamily: laung == "ar" && "Cairo-Light" }} >{message.created_on}</span>
                </div>
                <div ref={messagesEndRef} ></div>
              </div>
            ))}
          </div>
        }
      </div>
      {showEmojis && <EmojiPicker onEmojiClick={(e) => handleSelectEmoji(e)} />}

      {
        orderData && (orderData.status !== "105" || orderData.status !== "106") &&
        <>
          {
            (fileName && chatPic) &&
            <Box
              style={{ display: "flex", padding: "4px", alignItems: "center", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex", alignItems: "center" }} >
                {fileName.includes(".pdf") ? <PictureAsPdfIcon fontSize="large" /> : <InsertDriveFileIcon fontSize="large" />}
                <p style={{ fontSize: "16px", fontFamily: "Cairo-Regular", color: "#2a2821", marginLeft: "15px" }} >
                  {fileName}
                </p>
              </div>
              <img src={Close} alt=""
                onClick={() => handleClerImageData()}
              />
            </Box>
          }
          {(!fileName && chatPic) && <Box sx={style}>
            <img src={chatPic} alt="" style={{ height: "50px", width: "50px" }} />
          </Box>}

          <div className="chat-input" >
            {/* <ImageIcon
              color='black'
              style={{ cursor: "pointer" }}
              onClick={() => inputFileRef.current.click()}
            /> */}

            {/* <span onClick={() => seetShowEmojis(!showEmojis)} style={{ fontSize: "20px", padding: "0 5px" }} >🙂</span> */}
            {/* <textarea
              value={textInput}
              className="chat-input-textarea"
              rows="1"
              placeholder={t("enter your message")}
              ref={textareaRef}
              onChange={handleTextareaChange}
              onKeyDown={handleKeyDown}
              disabled
              style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }}
            > </textarea> */}
            <input
              type="file"
              ref={inputFileRef}
              // style={{ display: 'none' }}
              accept="image/png, image/jpeg,image/jpg, image/gif, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleFileInputChange}
            />
            <button
              disabled={!chatPic}
              className="chat-input-button" onClick={sendMessage}
              style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }}
            >
              {t("send")}
            </button>

          </div>
        </>
      }
      <Modal
        isOpen={imgPreview}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <img src={displayImage} alt="" style={{ width: "100%", objectFit: "contain", height: "100%" }} />
      </Modal>
    </div>
  )
}

export default ChatModal