import React, {
  useRef,
  useState,
  useEffect,
} from 'react';
import Filters from './Filters';
import Products from './Products';
import Pagination from './Pagination'
import styles from "./ViewAll.module.scss";
import HeaderViewAll from './HeaderViewAll';
import Nodata from '../../constants/Nodata';
import { useTranslation } from 'react-i18next';
import { ADMIN } from '../../services/admin.service';
import ProductCard from '../../constants/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ProductLoader from '../../constants/ProductLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getProductList } from '../../services/Product.service';
import { getFilterDetails } from '../../redux/slice/filterSilce';
import FilterLoader from '../../constants/PreLoaders/FilterLoader';
import { getAllCategoris } from '../../services/categories.services';
import { updateCanonicalLinkforblog } from '../../redux/actions/seodata';
import CatalogMagic from '../../constants/PreLoaders/CatelogMagicLoaders';
import DummyLoader from '../../constants/PreLoaders/DummyLoader/DummyLoader';
import BouncingLoader from '../../constants/BouncingLoader';

const ScrollStyle = {
  height: "100%",
  overflow: "auto"
}

const ViewAll = ({ setSeoData }) => {
  const params = useParams();
  const pNumber = useRef();
  const moreContent = useRef();
  const observer = useRef();
  const lastItemRef = useRef();

  const dispatch = useDispatch();
  const { categoryname, sub_sub_category, catID, offerID, subcategory, offerkey, vendor_id } = params;

  const { t } = useTranslation();
  const laung = localStorage.getItem("laung");
  const vendorBrowse = localStorage.getItem("browse_vendor")
  const fromsearch = localStorage.getItem("fromsearch") ?? false;
  const categoryName = localStorage.getItem("categoryname");
  const filteredSubCategories = localStorage.getItem("filteredSubCategories");
  const filteredCities = localStorage.getItem("filteredCities");
  const filteredBrands = localStorage.getItem("filteredBrands");
  const filterSortingID = localStorage.getItem("filterSortingID");
  const filterCustomerReview = localStorage.getItem("filterCustomerReview");
  const maximumprice = localStorage.getItem("maximum_price");
  const minimumprice = localStorage.getItem("minimum_price");


  const searchTerm = localStorage.getItem("searchTerm")
  const location = useLocation();

  const sortinArr = [
    {
      "id": 1,
      "title": t("new")
    },
    {
      "id": 8,
      "title": t("discount")
    },
    {
      "id": 7,
      "title": t("most rated")
    },
    {
      "id": 2,
      "title": t("old")
    },
    {
      "id": 5,
      "title": t("most ordered")
    },
    {
      "id": 6,
      "title": t("stock available")
    },
    {
      "id": 3,
      "title": t("hightolow")
    },
    {
      "id": 4,
      "title": t("lowtohigh")
    },
    {
      "id": 9,
      "title": t("most viewed")
    }
  ];

  const productList = useSelector((state) => state?.productList?.data?.response);
  const filterList = useSelector((state) => state?.filterDetails?.data?.response);

  const [categories, setCategories] = useState([]);
  const [showsort, setShowsort] = useState(false);
  const [productsdata, setData] = useState([]);
  moreContent.current = productsdata;

  const [pageNumber, setPageNumber] = useState(0);
  pNumber.current = pageNumber;
  const [selectedSubCat, setSelectedSubcat] = useState(offerID == 2 ? catID : sub_sub_category ? sub_sub_category : "");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCities, setSelectedCities] = useState([])
  const [sortID, setSortID] = useState(4);
  const [productCount, setProductCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [priceRange, setPriceRange] = useState([]);
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState(minimumprice ? minimumprice : "0")
  const [variationValues, setVariationValues] = useState([]);
  const [orderRatings, setOrderRatings] = useState(null);
  const [productLoader, setProductLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const loaderRef = useRef(null);

  useEffect(() => {
    vendor_id && localStorage.removeItem("searchTerm")
    !vendor_id && getAllCategoris(categoryname ? categoryname : categoryName, laung)
      .then(res => {
        if (res.data.status) { setCategories(res.data.response) }
      })
      .catch(error => ADMIN.toast.error(error));
  }, [categoryname]);


  useEffect(() => {
    filteredCities && setSelectedCities([filteredCities][0].split(","));
    filteredSubCategories && setSelectedSubcat(filteredSubCategories.split(","));
    filteredBrands && setSelectedBrands([filteredBrands][0].split(","));
    filterSortingID && setSortID(filterSortingID);
    filterCustomerReview && setOrderRatings(Number(filterCustomerReview));
  }, [])

  useEffect(() => {
    setMaxPrice(maximumprice ? maximumprice : filterList?.max_price)
  }, [filterList && filterList]);

  const handleProductLodader = () => {
    setTimeout(() => {
      setProductLoader(false)
    }, 1250)
  }

  useEffect(() => {
    handleNotScroll();
    scrollToTop()
    setHasMore(true)
  }, [categoryname, subcategory, sortID, selectedBrands, orderRatings, selectedSubCat, selectedCities, searchTerm]);

  useEffect(() => {
    pageNumber != 0 && handelGetProdutcs()
  }, [pageNumber])


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isFetching && hasMore) {
        setPageNumber((prevPage) => prevPage + 1);
      }
    }, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isFetching]);


  const handleClearFilter = () => {
    setHasMore(true)
    setProductLoader(true)
    setPriceRange([]);
    setMaxPrice(filterList?.max_price);
    setMinPrice("0");
    setVariationValues([]);
    setSelectedSubcat([]);
    setOrderRatings("");
    setSelectedCities("");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNotScroll = () => {
    setIsFetching(true);
    let data = {
      "department_id": vendorBrowse ? "" : categoryname ? categoryname : categoryName,
      "category_id": subcategory,
      "sub_category_id": filteredSubCategories ? filteredSubCategories : sub_sub_category,
      "brand_id": filteredBrands ? filteredBrands : selectedBrands.toString(),
      "offer_id": offerID === 1 ? catID : "",
      "index": pageNumber,
      "size": 40,
      "city_id": filteredCities ? filteredCities.toString() : "",
      "type_search": fromsearch == 'true' ? "1" : "0",
      "rating": orderRatings ? orderRatings : "",
      "sort": sortID,
      "section": offerkey ? offerkey : "",
      "search": searchTerm,
      "max_price": maximumprice == null ? 0 : maximumprice,
      "min_price": minimumprice == null ? 0 : minimumprice,
      "variation_value_id": variationValues.toString(),
      vendor_id,
    };
    getProductList(data)
      .then(res => {
        setLoading(false);
        handleProductLodader()
        if (res.data.status) {
          dispatch(getFilterDetails({
            categoryname: vendorBrowse ? "" : categoryname ? categoryname : categoryName,
            catID: subcategory,
            section: offerkey ? offerkey : "",
            subcategoryID: selectedSubCat,
            vendor_id: vendor_id ? vendor_id : ""
          }))
          if (res.data.response.product_list.length > 0) {
            setData(() => res.data.response.product_list);
            setProductCount(res.data.response.count);
            setSeoData(res.data.response);
            updateCanonicalLinkforblog(`https://www.venderlane.com${location.pathname + location.search}`);
          } else {
            setHasMore(false)
          }
          setIsFetching(false);
        }
      })
      .catch(e => {
        if (e.response.status == "404") {
          window.location.href = 'https://api.venderlane.com/web/v0_5/api/abc';
        }
        console.log(e, "the errors")
        setIsFetching(false);
      });
  };

  const fetchData = async () => {
    setPageNumber((prevstate) => Number(prevstate + 1))
    setTimeout(() => {
      handelGetProdutcs()
    }, 500)
  };

  const handelGetProdutcs = () => {
    setIsFetching(true);
    let data = {
      "department_id": vendorBrowse ? "" : categoryname ? categoryname : categoryName,
      "category_id": subcategory,
      "sub_category_id": filteredSubCategories ? filteredSubCategories : sub_sub_category,
      "brand_id": filteredBrands ? filteredBrands : selectedBrands.toString(),
      "offer_id": offerID === 1 ? catID : "",
      "index": pageNumber,
      "size": 40,
      "city_id": filteredCities ? filteredCities.toString() : "",
      "type_search": fromsearch == 'true' ? "1" : "0",
      "rating": orderRatings ? orderRatings : "",
      "sort": sortID,
      "section": offerkey ? offerkey : "",
      "search": searchTerm,
      "max_price": maximumprice == null ? 0 : maximumprice,
      "min_price": minimumprice == null ? 0 : minimumprice,
      "variation_value_id": variationValues.toString(),
      vendor_id,
    };
    getProductList(data)
      .then(res => {
        if (res.data.status) {
          setLoading(false);
          setIsFetching(false)
          // dispatch(getFilterDetails({
          //   categoryname: vendorBrowse ? "" : categoryname ? categoryname : categoryName,
          //   catID: subcategory,
          //   section: offerkey ? offerkey : "",
          //   subcategoryID: selectedSubCat,
          //   vendor_id: vendor_id ? vendor_id : ""
          // }))
          let newData = res.data.response.product_list
          if(newData.length > 0){
            setData(prevData => [...prevData, ...newData]);
            setProductCount(res.data.response.count);
            setSeoData(res.data.response);
            updateCanonicalLinkforblog(`https://www.venderlane.com${location.pathname + location.search}`);
          } else {
            setHasMore(false)
            handleProductLodader()

          }
      
        }

      })
      .catch(e => {ADMIN.toast.error(e);setIsFetching(false);})
  };
  const handleSorting = (SID) => {
    setProductLoader(true)
    if (sortID == SID) {
      setSortID("")
      localStorage.removeItem("filterSortingID")
    } else {
      setSortID(SID)
      localStorage.setItem("filterSortingID", SID)
    }
  };


  return (
    <div className={styles.container} >
      {fromsearch != true &&
        <header>
          {!location.pathname.includes("searchedresult")
            && categories &&
            categories.length != 0 &&
            !vendor_id &&
            <HeaderViewAll
              categories={categories}
              showsort={showsort}
              setShowsort={setShowsort}
              setPageNumber={setPageNumber}
              setSelectedSubcat={setSelectedSubcat}
              setSortID={setSortID}
              setLoading={setLoading}
              selectedSubCat={selectedSubCat}
              setProductLoader={setProductLoader}
              setSelectedBrands={setSelectedBrands}
            />}
        </header>
      }
      {productLoader && <ProductLoader />}
      <div className={styles.filter_products} >
        {filterList && filterList?.product_count != 0 ?
          <div className={styles.viewall_filters} >
            <Filters
              categories={categories}
              setSelectedSubcat={setSelectedSubcat}
              handleNotScrollForFilter={handleNotScroll}
              setPriceRange={setPriceRange}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
              setVariationValues={setVariationValues}
              selectedSubCat={selectedSubCat}
              variationValues={variationValues}
              setSelectedBrands={setSelectedBrands}
              selectedBrands={selectedBrands}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              sortID={sortID}
              clearFilter={handleClearFilter}
              setSortID={setSortID}
              orderRatings={orderRatings}
              setOrderRatings={setOrderRatings}
              setPageNumber={setPageNumber}
              setProductLoader={setProductLoader}
            />
          </div>
          :
          <div className={styles.filter_loader} >
            <FilterLoader />
          </div>
        }
        {
          loading ?
            <div style={{ width: "100%", height: "100%", display: "flex", columnGap: "30px", background: "#fff" }}>
              <div className={styles.web_loader} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  {/* <FilterLoader /> */}
                  <CatalogMagic />
                </div>
              </div>
              <div className={styles.mobile_loader} style={{ width: "100%" }}>
                <DummyLoader />
              </div>
            </div>
            :
            <div className={styles.producr_row} >
              <Products
                productList={productList}
                setPageNumber={setPageNumber}
                selectedSubCat={selectedSubCat}
                setSelectedSubcat={setSelectedSubcat}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
                selectedCities={selectedCities}
                setSelectedCities={setSelectedCities}
                sortID={sortID}
                setSortID={setSortID}
                productCount={productCount}
                setPriceRange={setPriceRange}
                setVariationValues={setVariationValues}
                handleNotScrollForFilter={handleNotScroll}
                clearFilter={handleClearFilter}
                variationValues={variationValues}
                orderRatings={orderRatings}
                setOrderRatings={setOrderRatings}
                setMaxPrice={setMaxPrice}
                setMinPrice={setMinPrice}
                categories={categories}
                setProductLoader={setProductLoader}
              />
              <div className={styles.products_data} id="scrollableDiv" >
                {/* <InfiniteScroll
                  height="100%"
                  dataLength={productsdata.length}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<BouncingLoader />}
                  scrollThreshold="10%"
                  scrollableTarget="scrollableDiv"
                > */}
                {/* <InfiniteScroll
                  dataLength={productsdata.length}
                  next={fetchData}
                  hasMore={true}
                  // height={1000}
                  loader={
                    <DummyLoader />
                  }
                  scrollThreshold="10%"
                  //  scrollableTarget="scrollableDiv"
                  endMessage={
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", display: "none" }} >
                      <h2 style={{ fontFamily: "avirain_mediam", fontSize: "18px", color: "#2A2821" }} >No More data is available</h2>
                    </div>
                  }
                > */}
                {
                  productCount.length != 0 ?
                    <>
                      <div className={styles.count_sort} >
                        {productCount &&
                          <p className={styles.total_products} >
                            {t('showing')} 1 - {productCount}  {t('productof')}  {productCount}
                            {/* {(pageNumber * 40) + 1} - {((pageNumber + 1) * 40) > productCount ? productCount : (pageNumber + 1) * 40} {t('productof')} {productCount} */}
                          </p>
                        }
                        <select id="sorting" alt="sort" title="sort" defaultValue={4} value={sortID} onChange={(e) => handleSorting(e.target.value)} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "Avenir Black" }} >
                          {sortinArr.map((sortingtype, sort_index) => (
                            <option style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "avirain_mediam" }} key={sort_index} value={sortingtype.id} >
                              {sortingtype.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={styles.top_products}  >
                        {
                          productsdata && productsdata.map((card, ind) => {
                            return (
                              <ProductCard
                                ref={ind === productsdata.length - 1 ? lastItemRef : null}
                                key={ind}
                                details={card}
                              />
                            )
                          })
                        }
                        <div ref={loaderRef} style={{ height: '20px' }} />
                      </div>
                      {isFetching && <BouncingLoader />}
                      {/* <Pagination
                          pageNumber={pageNumber}
                          totalitemcounts={productCount}
                          setPageNumber={setPageNumber}
                          setProductLoader={setProductLoader}
                        /> */}
                    </>
                    : <Nodata />
                }
                {/* </InfiniteScroll> */}
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default ViewAll;