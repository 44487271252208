import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const VillageList = createAsyncThunk("village_list",  async (cID) => {
  const response = await axiosInstance.get(`village?type=1&city_id=${cID}`)
  return response.data;
});


const VillageListSlice = createSlice({
  name:'city_region_list',
  initialState:{
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(VillageList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(VillageList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(VillageList.rejected, (state, action ) => {
      state.isError = true
    })
  }
})


export default VillageListSlice.reducer;