import React, { useEffect, useState } from 'react';
import styles from "./SingleSellerDetails.module.scss";
import {  singleSeller } from '../../services/sellerregistration.service';
import { useParams } from 'react-router';
import Mail from '../../assets/Images/svgs/mail.svg';
import Call from '../../assets/Images/svgs/call.svg';
import Web from '../../assets/Images/svgs/web.svg';
import Location from '../../assets/Images/svgs/location.svg';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};




const SingleSellerDetails = () => {

  const { sid } = useParams();
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));


  const [sellerInfo, setSellerInfo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [displayPic, setDisplayPic] = useState(null);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            setError(`Error getting location: ${error.message}`);
          }
        );
      } else {
        setError('Geolocation is not supported by your browser.');
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    singleSeller(sid)
      .then(res => {
        if (res.data.status) {
          setSellerInfo(res.data.response)
        }
      })
      .catch(e => console.error(e, "erroe"))
  }, []);

  const NavigateToMail = (vendor) => {
    const email = vendor;
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  const handleClick = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${sellerInfo.lat},${sellerInfo.lng}`;
    window.open(url, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showPicture = (picture) => {
    setOpen(true)
    setDisplayPic(picture)
  };


  const handleIframeClick = (pdfurl) => {
    const iframURL = pdfurl
    // Open a new tab with the iframe URL
    window.open(iframURL, '_blank');
  };

  const PdfImages = sellerInfo.length !== 0 && sellerInfo.gallery.filter(item => item.image.includes('.pdf'));
  const NonPdfImages = sellerInfo.length !== 0 && sellerInfo.gallery.filter(item => !item.image.includes('.pdf'));



  return (
    <div className={styles.container}>
      <div className={styles.seller_details} >
        <h1 className={styles.seller_heading} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("service provider information")}</h1>
        {
          sellerInfo &&

          <div className={styles.body_section} >
            <img
              src={sellerInfo.banner}
              alt={sellerInfo.business_name}
              title={sellerInfo.business_name}
              className={styles.seller_picture}
            />
            <div className={styles.name_timing} >
              <h1 className={styles.seller_name} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >
                {/* {sellerInfo.first_name} {sellerInfo.last_name} */}
                {sellerInfo.business_name}
              </h1>
              <div className={styles.seller_status} >
                <p style={sellerInfo.open_now == "1" ? { color: "#1AC300", fontFamily: laung == "ar" && "Cairo-Semibold" } : { color: "red", fontFamily: laung == "ar" && "Cairo-Semibold" }} >{sellerInfo.open_now == "1" ? (t("open")) : (t("closed"))}  <span>{t("Working Hours")} ( {sellerInfo.open_by} - {sellerInfo.close_by} )</span> </p>
              </div>
              <div className={styles.seller_contacts} >
                <a href={`tel:${sellerInfo.country_code}${sellerInfo.mobile_number}`} >
                  <div className={styles.contact_by_number} >
                    <img
                      src={Call}
                      alt="Call"
                    />
                    <p style={{ direction: "ltr", textAlign: laung == "ar" ? "right" : "left", fontFamily: laung == "ar" && "Cairo-Regulars" }}>{sellerInfo.country_code}-{sellerInfo.mobile_number}</p>
                  </div>
                </a>
                {sellerInfo.website && <a href={sellerInfo.website.includes("https://") ? sellerInfo.website : `https://${sellerInfo.website}`} target="_blank" >
                  <div className={styles.contact_by} >
                    <img
                      src={Web}
                      alt="website"
                    />
                    <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{sellerInfo.website}</p>
                  </div>
                </a>}
                <div className={styles.contact_by} onClick={() => NavigateToMail(sellerInfo.email_id)} >
                  <img
                    src={Mail}
                    alt="Mail"
                  />
                  <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{sellerInfo.email_id}</p>
                </div>
                <a
                  // getting the direaction  form the location
                  // href={`https://www.google.com/maps/dir/${location?.latitude},${location?.longitude}/${sellerInfo.lat},${sellerInfo.lng}`} 

                  // location the address on map
                  href={`https://www.google.com/maps/search/?api=1&query=${sellerInfo.lat},${sellerInfo.lng}`}
                  target="_blank"
                >
                  <div className={styles.contact_by} >
                    <img
                      src={Location}
                      alt="Location"
                    />
                    <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{t("locate")}</p>
                  </div>
                </a>
              </div>
              <p href="#"
                // onClick={() => handleClick()} 
                target='_blank'>
                <div className={styles.seller_address} >
                  <img
                    src={Location}
                    alt="address"
                  />
                  <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }}>{sellerInfo.business_address}</p>
                </div>
              </p>
            </div>
            <div className={styles.description} >
              <h2 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("Descriptions")}</h2>
              <p className={styles.description_details} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} dangerouslySetInnerHTML={{ __html: sellerInfo.service_description }} />
              {sellerInfo && sellerInfo.sp_category?.length != 0 && <p className={styles.categorie_label} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("serivece categories")}</p>}
              <div className={styles.product_categories} >
                {
                  sellerInfo && sellerInfo.sp_category?.map((cat, indi) => {
                    return (
                      <p className={styles.category_name} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} key={indi}>{cat.title}</p>
                    )
                  })
                }
                {/* <ul>
                  {
                    sellerInfo && sellerInfo.sp_category?.map((cat, indi) => {
                      return (
                        <li key={indi}>{cat.title}</li>
                      )
                    })
                  }
                </ul> */}
              </div>
            </div>

            {sellerInfo && sellerInfo.gallery?.length != 0 && PdfImages.length !== 0 &&
              <div className={styles.photo_details}  style={{    Border: "0",  boxShadow: "unset",  padding: "22px 0"}}>
                {PdfImages.length !== 0 && <h1 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }}  className={styles.doc_heading}  >{t("document view")}</h1>}
                {PdfImages.length !== 0 &&
                  <div className={styles.photos_doc} >
                    {PdfImages && PdfImages.map((pic, indi) => {
                      return (
                        <div key={indi} className={styles.single_ifrm} >
                          <iframe
                            title="PDF Viewer"
                            src={pic.image}
                            onClick={() => handleIframeClick(pic.image)}
                          >
                          </iframe>
                          <p className={styles.download_text}> <a style={{ textAlign: "center" }} href={pic.image} target='_blank'>{t("download file")}</a></p>
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
            {sellerInfo && sellerInfo.gallery?.length != 0 && NonPdfImages.length !== 0 &&
              <div className={styles.photo_details} >
                {NonPdfImages.length !== 0 && <h1 style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} className={styles.doc_heading} >{t("gallery view")}</h1>}
                {NonPdfImages.length !== 0 &&
                  <div className={styles.photos} >
                    {NonPdfImages && NonPdfImages.map((pic, indi) => {
                      return (
                        <div key={indi} >
                         { pic.image &&
                         <img
                            src={pic.image}
                            alt="Images"
                            title="Product Images"
                            onClick={() => showPicture(pic.image)}
                          />}
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
      <Modal
        isOpen={open}
        onRequestClose={() => (handleClose())}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <img src={displayPic} style={{ height: "100%", width: "100%" }} />
      </Modal>
    </div>
  )
}

export default SingleSellerDetails;

// return (
//   <div key={indi}>
//     {
//       pic.image.includes(".pdf") ?
//         <>
//           {pic.image && <div>
//             <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("document view")}</p>

//           </div>}
//         </>
//         :
//         <>
//           {pic.image && <div>

//             <img
//               src={pic.image}
//               alt="Images"
//               title="Product Images"
//               onClick={() => showPicture(pic.image)}
//             />
//           </div>}
//         </>
//     }
//   </div>
// )