import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const cityList = createAsyncThunk("citylist",  async () => {
  const response = await axiosInstance.get("city")
  return response.data;
});


const cityListSlice = createSlice({
  name:'cityList',
  initialState:{
    isLoading : false,
    data : null,
    isError : false
  },
  
  extraReducers: (builder) => {
    builder.addCase(cityList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(cityList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(cityList.rejected, (state, action ) => {
      state.isError = true
    })
  }
})


export default cityListSlice.reducer;