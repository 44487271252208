import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const RegionList = createAsyncThunk("region_list",  async () => {
  const response = await axiosInstance.get("village?type=3")
  return response.data;
});


const regionListSlice = createSlice({
  name:'region_list',
  initialState:{
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(RegionList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(RegionList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(RegionList.rejected, (state, action ) => {
      state.isError = true
    })
  }
})


export default regionListSlice.reducer;