import React, { useState, useEffect, useRef } from 'react';
import styles from "./Register.module.scss";
import UpArrow from "../../../assets/Images/svgs/up_arrow.svg";
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { useNavigate } from 'react-router';
import { getDepartments } from '../../../services/LandingPage';
import { registerUser, sendOTP, pendingregisterUser } from '../../../services/Profile';
import { ADMIN } from '../../../services/admin.service';
import { useTranslation } from 'react-i18next';
import { AddToCart, AddToWishList } from '../../../services/Product.service';
import { menuCounts } from '../../../redux/slice/menuCountSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Select from 'react-select';
import axiosInstance from '../../../services/api';
import Spinner from '../../../constants/spinner/Spinner';
import { generateToken } from '../../../utils/tokenGenerator';
import { fireStore } from '../../../firebase/config';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, } from "firebase/storage";
import ProductLoader from '../../../constants/ProductLoader';
import { getTimestampString } from '../../../constants/timestamp';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const Register = () => {

  const formikRef = useRef();
  const location = useLocation();
  const userMobileInfo = location.state;
  const laung = (localStorage.getItem('laung'));
  let storedToken = localStorage.getItem('uniqueToken');

  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [departments, setDepartMents] = useState("");
  const [countryCode, setCountryCode] = useState(userMobileInfo && userMobileInfo.country_code ? userMobileInfo.country_code : "+966");
  const [sentOTP, setSentOTP] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const today = formatDate(new Date());
  const regestrationSchema = yup.object({
    fullName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    mobileNumber: yup
      .string()
      .min(9, t("minimum number"))
      .max(10, t('Maximum of 10 number you can use'))
      .matches(phoneRegExp, t("phone number is not valid"))
      .required(t("mobile number is required")),
    emailID: yup
      .string()
      .email(t("enter a Valid mail address"))
      .test('email-validation', t("enter a Valid mail address"), (value) => {
        if (!value) return false;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value);
      })
      .required(t("mail is required")),
    companyName: yup
      .string()
      .required(t("company name is required")),
    certificate: yup
      .mixed()
      .required(t("file is Required*")),
    crNumber: yup
      .string()
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct CR number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct CR number"))
      .min(10, t("Minimum 10 digits Required")),

    department: yup
      .string()
      .required(t("department is required")),
    password: yup
      .string()
      .required(t("password is required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("must contain")),
    confirmPassword: yup
      .string()
      .required(t("confirm passowrd is required"))
      .oneOf([yup.ref("password"), null], t("passwords are not matching")),
    dob: yup
      .date()
      .max(new Date(), t('Date of birth cannot be in the future')),
    otp: yup
      .number()
      .required(t("otp require"))
      .min(6, t("minimum otp")),
    business_type: yup
      .string()
      .required(t('This field is required')),
    fl_number: yup.string()
      .min(8, t("Minimum 8 digits Required"))
      // .max(15, t("max num_fl"))
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct FL number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct FL number")),
  });

  const withoutOTPSchema = yup.object({
    fullName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    mobileNumber: yup
      .string()
      .min(9, t("minimum number"))
      .max(10, t('Maximum of 10 number you can use'))
      .matches(phoneRegExp, t("phone number is not valid"))
      .required(t("mobile number is required")),
    emailID: yup
      .string()
      .email(t("enter a Valid mail address"))
      .required(t("mail is required")),
    companyName: yup
      .string()
      .required(t("company name is required")),
    certificate: yup
      .mixed()
      .required(t("file is Required*")),
    crNumber: yup
      .string()
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct CR number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567890",
        "1234567891",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct CR number"))
      .min(10, t("Minimum 10 digits Required")),
    department: yup
      .string()
      .required(t("department is required")),
    password: yup
      .string()
      .required(t("password is required"))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("must contain")),
    confirmPassword: yup
      .string()
      .required(t("confirm passowrd is required"))
      .oneOf([yup.ref("password"), null], t("passwords are not matching")),
    dob: yup
      .date(),
    business_type: yup
      .string()
      .required(t('This field is required')),
    fl_number: yup.string()
      .min(8, t("Minimum 8 digits Required"))
      .matches(/^(?!.*(\d)\1{3}).*$/, t('Please enter correct FL number'))
      .notOneOf([
        '0123456789',
        '123456789',
        "1234567891",
        "1234567890",
        "012345678",
        "12345678",
        "01234567",
        "1234567",
        "0123456",
        "123456",
        "012345",
        "12345",
        "01234",
        "1234",
        "0123",
        "123",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
        "0000000000"], t("Please enter correct FL number")),
  });

  const handleGetDepartment = () => {
    getDepartments()
      .then(res => {
        if (res.data.status) {
          setDepartMents(res.data.response)
        }
      })
      .catch(e => console.error(e, "departments error"))
  }

  const handleTokenGenerator = () => {
    if (!storedToken) {
      storedToken = generateToken();
      localStorage.setItem('uniqueToken', storedToken);
    }
  }

  const handleGetPendingUsers = () => {
    axiosInstance.get(`pending_register.php?type=2&token=${storedToken}`)
      .then(res => {
        if (res.data.status) {
          res.data.response.fullName && formikRef.current.setFieldValue("fullName", res.data.response.fullName);
          res.data.response.lastName && formikRef.current.setFieldValue("lastName", res.data.response.lastName)
          res.data.response.emailID && formikRef.current.setFieldValue("emailID", res.data.response.emailID)
          res.data.response.companyName && formikRef.current.setFieldValue("companyName", res.data.response.companyName)
          res.data.response.crNumber && formikRef.current.setFieldValue("crNumber", res.data.response.crNumber)
          res.data.response.department && formikRef.current.setFieldValue("department", res.data.response.department)
          res.data.response.mobileNumber && formikRef.current.setFieldValue("mobileNumber", res.data.response.mobileNumber)
          res.data.response.password && formikRef.current.setFieldValue("password", res.data.response.password)
          res.data.response.confirmPassword && formikRef.current.setFieldValue("confirmPassword", res.data.response.confirmPassword)
          res.data.response.dob && formikRef.current.setFieldValue("dob", res.data.response.dob)
          res.data.response.business_type && formikRef.current.setFieldValue("business_type", res.data.response.business_type)
          res.data.response.fl_number && formikRef.current.setFieldValue("fl_number", res.data.response.fl_number);
          res.data.response.vat_number && formikRef.current.setFieldValue("vat_number", res.data.response.vat_number);
          res.data.response.country_code && setCountryCode(res.data.response.country_code);
        } else {
          console.log(res.data.response.message, "the false status")
        }
      })
      .catch(error => {
        console.log(error.message, "the pending user errors")
      })
  };

  useEffect(() => {
    handleTokenGenerator()
    handleGetDepartment()
    handleGetPendingUsers();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    } else {
      setCanResend(true)
    }
  }, [timer])

  const InitialValues = {
    fullName: "",
    lastName: "",
    emailID: "",
    companyName: "",
    crNumber: "",
    department: "",
    mobileNumber: userMobileInfo ? userMobileInfo.mobile_number : "",
    password: "",
    confirmPassword: "",
    dob: "",
    otp: "",
    business_type: "",
    fl_number: "",
    vat_number: "",
  };

  const withoutOTPInitialValues = {
    fullName: "",
    lastName: "",
    emailID: "",
    companyName: "",
    crNumber: "",
    department: "",
    mobileNumber: userMobileInfo ? userMobileInfo.mobile_number : "",
    password: "",
    confirmPassword: "",
    dob: "",
    business_type: "",
    fl_number: "",
    vat_number: "",
  };

  const handleSendOtp = (formik) => {
    formik.setFieldValue("otp", "")
    setTimer(30)
    setCanResend(false);
    const checkData = {
      "country_code": countryCode,
      "mobile_number": formik.values.mobileNumber
    }
    sendOTP(checkData)
      .then(res => {
        if (res.data.status) {
          setSentOTP(true)
          ADMIN.toast.success(res.data.response.message);
        } else {
          ADMIN.toast.error(res.data.response.message);
        }
      })
      .catch(e => {
        ADMIN.toast.error(e);
      })
  };

  const handleRegisterUser = (values) => {
    const userInfo = {
      "first_name": values.fullName,
      "last_name": values.lastName,
      "country_code": countryCode,
      "mobile_number": userMobileInfo && userMobileInfo.mobile_number ? userMobileInfo.mobile_number : values.mobileNumber,
      "email_id": values.emailID,
      "password": values.password,
      "reg_from": "1",
      "dob": values.dob,
      "cr_number": values.business_type == "1" ? values.crNumber : "",
      "company_name": values.companyName,
      "department_id": values.department,
      "otp": values.otp,
      "business_type": values.business_type,
      "fl_number": values.business_type == "2" ? values.fl_number : "",
      'token': storedToken,
      "cr_image": certificate,
      "vat_number": values.vat_number,
    }
    certificate && setLoading(true)
    certificate && axiosInstance.post("register_verify_otp", userInfo)
      .then(res => {
        if (res.data.status) {
          setLoading(false)
          localStorage.removeItem("uniqueToken");
          localStorage.setItem("venlogtoken", res.data.response.token);
          localStorage.setItem("departmentID", res.data.response.department_id)
          const productID = (localStorage.getItem('productID'));
          const productQuantity = (localStorage.getItem('productQuantity'));
          const wishlistStat = (localStorage.getItem("stat"))
          if (productID && productQuantity) {
            const data = { "product_id": productID, "quantity": productQuantity }
            AddToCart(data)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "productQuantity"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                } else { console.error(res.data.response.message) }
              })
              .catch(e => console.error(e, "add to kart error"))
          } else if (wishlistStat && productID) {
            AddToWishList(productID, wishlistStat)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "stat"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                }
              })
              .catch(e => console.error(e, "add ro wishlist error"))
          }
          navigate(laung == 'en' ? `/${laung}` : "/")
          ADMIN.toast.success(res.data.response.message)
        } else {
          setLoading(false)
          ADMIN.toast.error(res.data.response.message)
        }
      })
      .catch(e => {
        setLoading(false)
        ADMIN.toast.error(e.message)
      }
      )
  };

  const RegisterWithoutOtp = (values) => {
    const withoutOtpuser = {
      "first_name": values.fullName,
      "last_name": values.lastName,
      "country_code": countryCode,
      "mobile_number": userMobileInfo && userMobileInfo.mobile_number ? userMobileInfo.mobile_number : values.mobileNumber,
      "email_id": values.emailID,
      "password": values.password,
      "reg_from": "1",
      "dob": values.dob,
      "cr_number": values.business_type == "1" ? values.crNumber : "",
      "company_name": values.companyName,
      "department_id": values.department,
      "business_type": values.business_type,
      "fl_number": values.business_type == "2" ? values.fl_number : "",
      'token': storedToken,
      "cr_image": certificate,
      "vat_number": values.vat_number,
    }
    certificate && setLoading(true)
    certificate && registerUser(withoutOtpuser)
      .then(res => {
        if (res.data.status) {
          localStorage.removeItem("uniqueToken");
          setLoading(false)
          localStorage.setItem("venlogtoken", res.data.response.token);
          localStorage.setItem("departmentID", res.data.response.department_id)
          const productID = (localStorage.getItem('productID'));
          const productQuantity = (localStorage.getItem('productQuantity'));
          const wishlistStat = (localStorage.getItem("stat"))
          if (productID && productQuantity) {
            const data = { "product_id": productID, "quantity": productQuantity }
            AddToCart(data)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "productQuantity"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                } else { console.error(res.data.response.message) }
              })
              .catch(e => console.error(e, "add to kart error"))
          } else if (wishlistStat && productID) {
            AddToWishList(productID, wishlistStat)
              .then(res => {
                if (res.data.status) {
                  let keysToRemove = ["productID", "stat"];
                  keysToRemove.forEach(k => localStorage.removeItem(k))
                  dispatch(menuCounts())
                }
              })
              .catch(e => console.error(e, "add ro wishlist error"))
          }
          navigate(laung == 'en' ? `/${laung}` : "/")
          ADMIN.toast.success(res.data.response.message)
        } else {
          setLoading(false)
          ADMIN.toast.error(res.data.response.message)
        }
      })
      .catch(e => { setLoading(false); ADMIN.toast.error(e) }
      )
  }

  const handleWithoutReg = (formik, name) => {
    formik.setFieldTouched(name)
    const data = {
      ...formik.values,
      type: "2",
      token: storedToken,
      "country_code": countryCode,
    }
    pendingregisterUser(data)
      .then((res) => {
        if (res.data.status) {
          // console.log(res.data.response.message, "success")
        } else {
          console.log(res.data.response.message, "failed")
        }
      })
      .catch(error => console.log(error.message, "the error message"))
  }

  const handlefirebaseUpload = (file) => {
    const storageref = ref(getStorage(fireStore), `/Propety_type/${file.name}/${getTimestampString()}`)
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed", (snapshot) => {
        const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      },
      (err) => {
        ADMIN.toast.error(err)
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setCertificate(url)
          return
        }).catch((err) => {
          ADMIN.toast.error(err)
        })
      }
    );
  };

  return (
    <div className={styles.container} >
      <div className={styles.body} >
        <p className={styles.login_text} style={{ fontFamily: "Cairo-Semibold" }} >{t("register with us")}</p>
        <Formik
          innerRef={formikRef}
          initialValues={userMobileInfo && userMobileInfo.mobile_number ? withoutOTPInitialValues : InitialValues}
          validationSchema={userMobileInfo && userMobileInfo.mobile_number ? withoutOTPSchema : regestrationSchema}
          onSubmit={(values, errors, setErrors, isSubmitting) => {
            values.business_type == 1 && !values.crNumber && setErrors({ "crNumber": t("cr number is required") })
            values.business_type == 2 && !values.fl_number && setErrors({ "crNumber": t('This field is required') })
            !isSubmitting && document.getElementById('mobile_number').scrollIntoView();
            if ((values.business_type == 1 && values.crNumber && !errors.crNumber) || (values.business_type == 2 && values.fl_number && !errors.fl_number)) {
              userMobileInfo && userMobileInfo.mobile_number ? RegisterWithoutOtp(values) : handleRegisterUser(values)
            }
          }}
        >
          {
            (formik) => {
              console.log(formik.errors, "the errors")
              return (
                <Form>
                  <div className={styles.inputs} >
                    <div className={styles.single_input} >
                      <label style={{ fontFamily: "Cairo-Regular" }} htmlFor="fullName">{t("first name")}</label>
                      <input
                        id="fullName"
                        name="fullName"
                        className={styles.input}
                        type="text"
                        placeholder={t("Please enter the first name")}
                        onChange={formik.handleChange("fullName")}
                        value={formik.values.fullName}
                        onBlur={() => handleWithoutReg(formik, "fullName")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.fullName && formik.touched.fullName) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.fullName}</span>}
                    </div>

                    <div className={styles.single_input} id="mobile_number" >
                      <label htmlFor="fullName" style={{ fontFamily: "Cairo-Regular" }}>{t("last name")}</label>
                      <input
                        id="lastName"
                        name="lastName"
                        className={styles.input}
                        type="text"
                        placeholder={t("Please enter the last name")}
                        onChange={formik.handleChange("lastName")}
                        value={formik.values.lastName}
                        onBlur={() => handleWithoutReg(formik, "lastName")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.lastName && formik.touched.lastName) && <span style={{ fontFamily: "Cairo-Regular" }} className={styles.error_message}>{formik.errors.lastName}</span>}
                    </div>

                    <div className={styles.single_input}  >
                      <label style={{ fontFamily: "Cairo-Regular" }} htmlFor="mobileNumber">{t("mobile number")}</label>
                      <div className={laung == "ar" ? styles.ar_country_select : styles.country_select} >
                        <select
                          disabled={(userMobileInfo && userMobileInfo.country_code || sentOTP) ? true : false}
                          name="countrycode" id="countrycode" className={styles.select_option}
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <option value="+966">KSA</option>
                          <option value="+91">India</option>
                        </select>
                        <input
                          disabled={((userMobileInfo && userMobileInfo.mobile_number) || sentOTP) ? true : false}
                          id="mobileNumber"
                          maxLength={10}
                          name="mobileNumber"
                          className={styles.input}
                          type="text"
                          placeholder={t("Please enter the mobile number")}
                          onChange={formik.handleChange("mobileNumber")}
                          value={userMobileInfo && userMobileInfo.mobile_number ? userMobileInfo.mobile_number : formik.values.mobileNumber}
                          onBlur={() => handleWithoutReg(formik, "mobileNumber")}
                          style={{ fontFamily: "Cairo-Regular" }}
                        />
                        {!userMobileInfo &&
                          <button onClick={() => handleSendOtp(formik)} disabled={!canResend || formik.errors.mobileNumber || !formik.values.mobileNumber} className={styles.otp_btn} >
                            {canResend ? t("send otp") : `${t("Resend OTP in")} ${timer} ${t("Seconds")}`}
                          </button>}
                      </div>
                      {(formik.errors.mobileNumber && formik.touched.mobileNumber) && <span style={{ fontFamily: "Cairo-Regular" }} className={styles.error_message} >{formik.errors.mobileNumber}</span>}
                      {(formik.values.mobileNumber && formik.values.mobileNumber.length >= 9 && formik.errors.otp && !sentOTP && !userMobileInfo) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.otp}</span>}
                    </div>
                    {!userMobileInfo && sentOTP && <div className={styles.single_input_mobile}  >
                      <label htmlFor="otp" style={{ fontFamily: "Cairo-Regular" }}> {t("One Time Password")}</label>
                      <OtpInput
                        containerStyle={{
                          direction: "ltr"
                        }}
                        value={formik.values.otp}
                        onChange={formik.handleChange("otp")}
                        numInputs={6}
                        inputType="number"
                        inputStyle={{
                          height: "52px",
                          width: "52px",
                          fontSize: "14px",
                          backgroundColor: "#EDE3CA",
                          borderRadius: "10px",
                          border: "1px solid #2A2821"
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                      {(formik.errors.otp && formik.touched.otp) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.otp}</span>}
                    </div>}

                    <div className={styles.single_input} >
                      <label style={{ fontFamily: "Cairo-Regular" }} htmlFor="emailID">{t("email")}</label>
                      <input
                        id="emailID"
                        name="emailID"
                        className={styles.input}
                        type="email"
                        placeholder={t("Please enter the valid email")}
                        onChange={formik.handleChange("emailID")}
                        value={formik.values.emailID}
                        onBlur={() => handleWithoutReg(formik, "emailID")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.emailID && formik.touched.emailID) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.emailID}</span>}
                    </div>

                    {!userMobileInfo && sentOTP && <div className={styles.single_input_website}  >
                      <label htmlFor="otp" style={{ fontFamily: "Cairo-Regular" }}> {t("One Time Password")}</label>
                      <OtpInput
                        containerStyle={{
                          direction: "ltr"
                        }}
                        value={formik.values.otp}
                        onChange={formik.handleChange("otp")}
                        numInputs={6}
                        inputType="number"
                        inputStyle={{
                          height: "52px",
                          width: "52px",
                          fontSize: "14px",
                          backgroundColor: "#EDE3CA",
                          borderRadius: "10px",
                          border: "1px solid #2A2821"
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                      {(formik.errors.otp && formik.touched.otp) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.otp}</span>}
                    </div>}

                    <div className={styles.single_input} >
                      <label htmlFor="companyName" style={{ fontFamily: "Cairo-Regular" }}>{t("company name")}</label>
                      <input
                        id="companyName"
                        name="companyName"
                        className={styles.input}
                        type="text"
                        placeholder={t("company name")}
                        onChange={formik.handleChange("companyName")}
                        value={formik.values.companyName}
                        onBlur={() => handleWithoutReg(formik, "companyName")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.companyName && formik.touched.companyName) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.companyName}</span>}
                    </div>
                    <div className={styles.single_input} >
                      <label htmlFor="vat_number" style={{ fontFamily: "Cairo-Regular" }}>{t("VAT Number")}</label>
                      <input
                        id="vat_number"
                        name="vat_number"
                        className={styles.input}
                        type="number"
                        placeholder={t("VAT Number")}
                        onChange={formik.handleChange("vat_number")}
                        value={formik.values.vat_number}
                        onBlur={() => handleWithoutReg(formik, "vat_number")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                    </div>
                    <div className={styles.single_input} >
                      <label htmlFor="business_type" style={{ fontFamily: "Cairo-Regular" }}>{t("Business Type")}</label>
                      <select
                        className={styles.input}
                        id="business_type"
                        name="business_type"
                        placeholder={t("Business Type")}
                        value={formik.values.business_type}
                        onChange={formik.handleChange("business_type")}
                        onBlur={() => handleWithoutReg(formik, "business_type")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      >
                        <option value="" disabled >{t("Business Type")}</option>
                        <option value={"1"} style={{ fontFamily: "Cairo-Regular" }}>{t("Commercial entity")}</option>
                        <option value={"2"} style={{ fontFamily: "Cairo-Regular" }}>{t("Freelance")}</option>
                      </select>
                      {(formik.errors.business_type && formik.touched.business_type) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.business_type}</span>}
                    </div>

                    {formik.values.business_type === "1" &&
                      <div className={styles.single_input} >
                        <label htmlFor="crNumber" style={{ fontFamily: "Cairo-Regular" }}>{t("cr number")}</label>
                        <input
                          // maxLength={15}
                          minLength={10}
                          id="crNumber"
                          required
                          name="crNumber"
                          className={styles.input}
                          type="number"
                          placeholder={t("cr number")}
                          onChange={formik.handleChange("crNumber")}
                          value={formik.values.crNumber}
                          onBlur={() => handleWithoutReg(formik, "crNumber")}
                          style={{ fontFamily: "Cairo-Regular" }}
                        />
                        {(formik.errors.crNumber) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.crNumber}</span>}
                      </div>}

                    {formik.values.business_type === "2" &&
                      <div className={styles.single_input} >
                        <label htmlFor="fl_number" style={{ fontFamily: "Cairo-Regular" }}>{t("freelance license number")}</label>
                        <input
                          // maxLength={15}
                          minLength={8}
                          id="fl_number"
                          name="fl_number"
                          className={styles.input}
                          type="number"
                          required
                          placeholder={t("freelance license number")}
                          onChange={formik.handleChange("fl_number")}
                          value={formik.values.fl_number}
                          onBlur={() => handleWithoutReg(formik, "fl_number")}
                          style={{ fontFamily: "Cairo-Regular" }}
                        />
                        {(formik.errors.fl_number) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.fl_number}</span>}
                      </div>}

                    {
                      formik.values.business_type &&
                      <div className={styles.single_input} >
                        <label style={{ fontFamily: "Cairo-Regular" }}>{formik.values.business_type === "2" ? t("Free-Lance Certificate") : t("cr certificate")}</label>
                        <input
                          id="certificate"
                          name="certificate"
                          className={styles.input}
                          style={{ alignItems: "center", lineHeight: "100%" }}
                          type="file"
                          required
                          onChange={(e) => (handlefirebaseUpload(e.target.files[0]), formik.setFieldValue('certificate', e.target.files[0]))}
                        />
                        {(formik.errors.certificate && formik.touched.certificate) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.certificate}</span>}
                      </div>
                    }
                    <div className={styles.single_input} >
                      <label htmlFor="department" style={{ fontFamily: "Cairo-Regular" }}>{t("department")}</label>
                      {departments &&
                        <Select
                          isMulti
                          getOptionLabel={option => option.title}
                          getOptionValue={option => `${option.id}`}
                          name="colors"
                          onChange={values => {
                            const selectedIds = values.map(option => option.id);
                            formik.setFieldValue("department", selectedIds.join(","));
                          }}
                          options={departments}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder={t("department")}
                          onBlur={() => handleWithoutReg(formik, "department")}
                          value={departments.filter((dept) => formik.values.department && formik.values.department.includes(dept.id))}
                        />
                      }
                      {(formik.errors.department && formik.touched.department) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }}>{formik.errors.department}</span>}
                    </div>

                    <div className={styles.single_input} >
                      <label htmlFor="Password" style={{ fontFamily: "Cairo-Regular" }} >{t("password")}</label>
                      <input
                        id="password"
                        name="password"
                        className={styles.input}
                        type="password"
                        placeholder={t("Please enter the password")}
                        onChange={formik.handleChange("password")}
                        value={formik.values.password}
                        onBlur={() => handleWithoutReg(formik, "password")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.password && formik.touched.password) && <span className={styles.error_message} >{formik.errors.password}</span>}
                    </div>

                    <div className={styles.single_input} >
                      <label htmlFor="confirmPassword" style={{ fontFamily: "Cairo-Regular" }}>{t("confirm password")}</label>
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        className={styles.input}
                        type="password"
                        placeholder={t("Please enter the confirm password")}
                        onChange={formik.handleChange("confirmPassword")}
                        value={formik.values.confirmPassword}
                        onBlur={() => handleWithoutReg(formik, "confirmPassword")}
                        style={{ fontFamily: "Cairo-Regular" }}
                      />
                      {(formik.errors.confirmPassword && formik.touched.confirmPassword) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.confirmPassword}</span>}
                    </div>

                    <div className={styles.single_input} >
                      <label htmlFor="dob" style={{ fontFamily: "Cairo-Regular" }}> {t("dob")}</label>
                      <input
                        id="dob"
                        name="dob"
                        className={styles.input}
                        type="date"
                        onChange={formik.handleChange("dob")}
                        value={formik.values.dob}
                        max={today}
                        onBlur={() => handleWithoutReg(formik, "dob")}
                      />
                      {(formik.errors.dob) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.dob}</span>}
                    </div>
                  </div>

                  <button
                    disabled={loading}
                    // onClick={() => {
                    //   formik.values.business_type == 1 && !formik.values.crNumber && formik.setErrors({ "crNumber": t("cr number is required") })
                    //   formik.values.business_type == 2 && !formik.values.fl_number && formik.setErrors({ "crNumber": t('This field is required') })
                    //   !formik.isSubmitting && document.getElementById('mobile_number').scrollIntoView();
                    //   if ((formik.values.business_type == 1 && formik.values.crNumber && !formik.errors.crNumber) || (formik.values.business_type == 2 && formik.values.fl_number && !formik.errors.fl_number)) {
                    //     userMobileInfo && userMobileInfo.mobile_number ? RegisterWithoutOtp(formik.values) : handleRegisterUser(formik.values)
                    //   }
                    // }} 
                    style={{ fontFamily: "Cairo-Regular" }} >{loading ? <Spinner /> : t("submit")}</button>
                </Form>
              )
            }
          }
        </Formik>
        {loading && <ProductLoader />}
        <p className={styles.create_account} style={{ fontFamily: "Cairo-Regular" }} >{t("already have account")}? <span onClick={() => navigate(laung == 'en' ? `/${laung}/login` : "/login")} style={{ fontFamily: "Cairo-Regular" }} >{t("login here")}<img src={UpArrow} alt="" style={{ height: "15px", width: "15px" }} /></span></p>
      </div>
    </div>
  )
}

export default Register