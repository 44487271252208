import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const CityregionList = createAsyncThunk("city_region_list",  async (rID) => {
  const response = await axiosInstance.get(`village?type=2&region_id=${rID}`)
  return response.data;
});


const CityregionListSlice = createSlice({
  name:'city_region_list',
  initialState:{
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(CityregionList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(CityregionList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(CityregionList.rejected, (state, action ) => {
      state.isError = true
    })
  }
})


export default CityregionListSlice.reducer;