import React, { useState } from 'react'
import { Route, Routes, } from 'react-router-dom';
import MainHomePage from '../Components/MainHomePage/MainHomePage';
import Register from '../Components/Login/Register/Register';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SellersProductList from '../Components/sellersDetails/SellersProductList';
import SingleSellerDetails from '../Components/sellersDetails/SingleSellerDetails';
import ThankYou from '../Components/Login/Register/seller/ThankYou';
import Account from '../Components/account/Account';
import Orders from '../Components/Profile/orders/Orders';
import RequestSubmitted from '../Components/kart/RequestSubmitted';
import SelectAddressModal from '../Components/Modal/SelectAddressModal';
import LandingHeaderThree from '../Components/LandingThree/LandingHeaderThree/LandingHeaderThree';
import LatestFooter from '../Components/Home/Footer/LatestFooter';
import DepartmentTabwithCategoryList from '../Components/LandingThree/DepartmentTabwithCategoryList/DepartmentTabwithCategoryList';
import NotFoundPage from './NotFound/NotFoundPage';
import PaymentSuccess from '../Components/Profile/orders/payment/PaymentSuccess';
import PaymentFailed from '../Components/Profile/orders/payment/PaymentFailed';


const AboutUsMain = React.lazy(() => import('../Components/Terms&conditions/AboutUsMain'));
const OurStoryMain = React.lazy(() => import('../Components/Terms&conditions/OurStoryMain'));
const FAQMain = React.lazy(() => import('../Components/Terms&conditions/FAQMain'));
const TermsConditionsMain = React.lazy(() => import('../Components/Terms&conditions/TermsConditionsMain'));
const PrivacyPolicyMain = React.lazy(() => import('../Components/Terms&conditions/PrivacyPolicyMain'));
const LandingThreeMain = React.lazy(() => import('../Components/LandingThree/LandingThreeMain'));
const LoginMain = React.lazy(() => import('../Components/Login/LoginMain'));
const SellerRegisOneMain = React.lazy(() => import('../Components/Login/Register/seller/SellerRegisOneMain'));
const VendorRegisterStepOneMain = React.lazy(() => import('../Components/Login/Register/VendorRegister/VendorRegisterStepOneMain'));
const CartMain = React.lazy(() => import('../Components/kart/CartMain'));
const MyChatMain = React.lazy(() => import('../Components/Profile/chat/MyChatMain'));
const UserProfileMain = React.lazy(() => import('../Components/Profile/userprofile/UserProfileMain'));
const AllOrdersMain = React.lazy(() => import('../Components/Profile/orders/allorders/AllOrdersMain'));
const SavedAddressMain = React.lazy(() => import('../Components/address/SavedAddressMain'));
const WishlistMain = React.lazy(() => import('../Components/wishlist/WishlistMain'));
const SingleProductMain = React.lazy(() => import('../Components/SingleProduct/SingleProductMain'));
const BlogDetailsMain = React.lazy(() => import('../Components/Blogs/BlogDetailsMain'));
const BlogListingMain = React.lazy(() => import('../Components/Blogs/BlogListingMain'));
const ViewAllMain = React.lazy(() => import('../Components/ViewAll/ViewAllMain'));


const AppRouter = () => {
  const [show, setShow] = useState('');
  const laung = localStorage.getItem('laung');

  return (
    <>
      <ToastContainer className="unselectable" />
      <LandingHeaderThree />
      <Routes>
        <Route path={laung == 'en' ? `/${laung}` : "/"} element={<LandingThreeMain />} key="/" />
        <Route path={laung == "en" ? `${laung}/category` : "/category"} element={<DepartmentTabwithCategoryList />} />
        <Route path={laung == "en" ? `${laung}/account` : "account"} element={<Account />} >
          <Route path={laung == "en" ? `/${laung}/account` : "/account"} element={<AllOrdersMain />} />
          <Route path="order_status/:ovid" element={<Orders />} />
          <Route path="my_profile" element={<UserProfileMain />} />
          <Route path="saved_address" element={<SavedAddressMain />} />
          <Route path="chats" element={<MyChatMain />} />
        </Route>
        <Route path={laung == "en" ? `${laung}/all_products/:offertype/:offerkey` : "all_products/:offertype/:offerkey"} element={<ViewAllMain />} />
        <Route path={laung == "en" ? `${laung}/category/:categoryname/*` : "/category/:categoryname/*"} element={<MainHomePage />} />
        {/* <Route path={laung == "en" ? `${laung}/category/:categoryname` :"/category/:categoryname"} element={<ViewAllMain />} /> */}
        <Route path={laung == "en" ? `${laung}/all_products/vendor/:vendor_id` : "all_products/vendor/:vendor_id"} element={<ViewAllMain />} />
        <Route path={laung == "en" ? `${laung}/vendor/all_products/:vendor_id` : "vendor/all_products/:vendor_id"} element={<ViewAllMain />} />
        <Route path={laung == "en" ? `${laung}/:producturl/p/:productID` : "/:producturl/p/:productID"} element={<SingleProductMain />} />
        <Route path={laung == 'en' ? `/${laung}/login` : "/login"} element={<LoginMain />} />
        <Route path={laung == "en" ? `${laung}/wish_list` : "/wish_list"} element={<WishlistMain />} />
        <Route path={laung == 'en' ? `/${laung}/my_cart` : "/my_cart"} element={<CartMain />} />
        <Route path={laung == "en" ? `${laung}/select_address` : "/select_address"} element={<SelectAddressModal />} />
        <Route path={laung == "en" ? `${laung}/order_submited` : "/order_submited"} element={<RequestSubmitted />} />
        <Route path={laung == "en" ? `${laung}/sellers_list` : "sellers_list"}  >
          <Route path={laung == "en" ? `/${laung}/sellers_list` : "/sellers_list"} element={<SellersProductList />} />
          <Route path="seller_details/:sid" element={<SingleSellerDetails />} />
          <Route path="thank_you" element={<ThankYou />} />
        </Route>

        <Route path={laung == "en" ? `${laung}/registration` : "registration/"} element={<Register />} />
        <Route path={laung == "en" ? `${laung}/seller_register` : "seller_register"} >
          <Route path={laung == "en" ? `/${laung}/seller_register` : "/seller_register"} element={<SellerRegisOneMain />} />
          <Route path="thank_you" element={<ThankYou />} />
        </Route>
        <Route path={laung == "en" ? `${laung}/vendor_register` : "vendor_register"} element={<VendorRegisterStepOneMain />} />
        <Route path="thank_you" element={<ThankYou />} />
        <Route path={laung == "en" ? `${laung}/about_us` : "about_us"} element={<AboutUsMain />} />
        <Route path={laung == "en" ? `${laung}/our_story` : "our_story"} element={<OurStoryMain />} />
        <Route path={laung == "en" ? `${laung}/faqs` : "faqs"} element={<FAQMain />} />
        <Route path={laung == "en" ? `${laung}/terms_conditions` : "terms_conditions"} element={<TermsConditionsMain />} />
        <Route path={laung == "en" ? `${laung}/privacy_policy` : "privacy_policy"} element={<PrivacyPolicyMain />} />
        <Route path={laung == "en" ? `${laung}/blogs` : "blogs"} element={<BlogListingMain />} />
        <Route path={laung == "en" ? `${laung}/:blogurl/b/:id` : "/:blogurl/b/:id"} element={<BlogDetailsMain />} />
        <Route path={laung == "en" ? `${laung}/payment-success/:ovid` : "/payment-success/:ovid"} element={<PaymentSuccess />} />
        <Route path={laung == "en" ? `${laung}/payment-failed/:ovid` : "/payment-failed/:ovid"} element={<PaymentFailed />} />
        <Route path="/notfoundpage" element={<NotFoundPage setShow={setShow} />} />

      </Routes>
      <LatestFooter />
    </>
  )
}

export default AppRouter;
